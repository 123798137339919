<template>
  <div>
    <Title :pages="pages">
      <Button @click="promptImport = true" status="primary"> Create </Button>
    </Title>

    <ResourceTable
      url="workflows-imports"
      :columns="columns"
      :options="resourceTableOptions"
    />

    <Import v-if="promptImport" @close="promptImport = false" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Button from "@/components/common/Button";
import Title from "@/components/common/Title";
import Create from "@/components/workflows/Create";
import Import from "@/components/workflows/Import";
import ResourceTable from "@/components/common/resources/Table";
import { columns } from "@/views/workflows/columns";

const pages = [
  { name: "Workflow Imports", href: "workflows.imports.index", current: true },
];

export default defineComponent({
  components: {
    Title,
    Button,
    Import,
    ResourceTable,
  },

  setup() {
    return {
      pages,
      columns,
      promptCreate: ref(false),
      promptImport: ref(false),
      resourceTableOptions: {
        deleteUrl: (item) => `workflows-imports/${item._id}`,
        viewRoute: (item) => ({
          name: "workflows.imports.view",
          params: { workflowImport: item._id },
        }),
      },
    };
  },
});
</script>
