<template>
  <Modal
    open
    action="Create"
    :loading="loading"
    @close="$emit('close')"
    @submit="form.submit()"
  >
    <Form
      ref="form"
      :schema="schema"
      :value="formValues"
      @submit="handleCreate"
    >
      <template #default="{ submit, values, errors, input, blue, focus }">
        <form @submit.prevent="submit">
          <div class="flex text-gray-900">
            <h2 class="flex flex-1 font-medium items-center leading-4 text-lg">
              Create Workflow
            </h2>
          </div>

          <Textbox
            name="name"
            class="mt-3"
            label="Name"
            :value="values.name"
            :error="errors('name')"
            v-on="{ input, blue, focus }"
          />
        </form>
      </template>
    </Form>
  </Modal>
</template>

<script>
import moment from "moment";
import { defineComponent, ref, inject } from "vue";
import { useRouter } from "vue-router";
import { object, string } from "yup";
import { dateTimeFormat } from "@/config/env";
import Modal from "@/components/common/Modal";
import form from "@/mixins/form";

export default defineComponent({
  emits: ["close", "update"],
  mixins: [form],

  components: {
    Modal,
  },

  setup() {
    const form = ref(null);
    const loading = ref(false);
    const router = useRouter();
    const $axios = inject("axios");

    const handleCreate = async ({ values }) => {
      loading.value = true;

      try {
        const { data: workflow } = await $axios.post("workflows", values);

        router.push({
          name: "workflows.edit",
          params: { workflow: workflow.data._id },
        });
      } catch (error) {
        //
      }

      loading.value = false;
    };

    return {
      form,
      loading,
      handleCreate,
      formValues: {
        name: `Workflow ${moment().format(dateTimeFormat)}`,
      },
      schema: object().shape({
        name: string().label("Name").required(),
      }),
    };
  },
});
</script>
