<template>
  <Modal
    open
    action="Import"
    :loading="loading"
    @close="$emit('close')"
    @submit="form.submit()"
  >
    <Form
      ref="form"
      :schema="schema"
      :value="formValues"
      @submit="handleImport"
    >
      <template #default="{ submit, values, errors, input, blue, focus }">
        <form @submit.prevent="submit">
          <div class="flex text-gray-900">
            <h2 class="flex flex-1 font-medium items-center leading-4 text-lg">
              Import SEED
            </h2>
          </div>

          <File
            name="file"
            class="mt-3"
            accept=".zip"
            :value="values.file"
            :error="errors('file')"
            v-on="{ input, blue, focus }"
          />
        </form>
      </template>
    </Form>
  </Modal>
</template>

<script>
import moment from "moment";
import { defineComponent, ref, inject } from "vue";
import { useRouter } from "vue-router";
import { object, string, mixed } from "yup";
import { dateTimeFormat } from "@/config/env";
import Modal from "@/components/common/Modal";
import form from "@/mixins/form";

export default defineComponent({
  emits: ["close", "update"],
  mixins: [form],

  components: {
    Modal,
  },

  setup() {
    const form = ref(null);
    const loading = ref(false);
    const router = useRouter();
    const $axios = inject("axios");

    const handleImport = async ({ values }) => {
      loading.value = true;

      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        const { data: imports } = await $axios.post(
          "workflows-imports",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        router.push({
          name: "workflows.imports.view",
          params: { workflowImport: imports.data._id },
        });
      } catch (error) {
        console.log(error);
        //
      }

      loading.value = false;
    };

    return {
      form,
      loading,
      handleImport,
      formValues: {
        file: null,
      },
      schema: object().shape({
        file: mixed().label("File").required(),
      }),
    };
  },
});
</script>
